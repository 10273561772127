import React from 'react'
import Layout from '../../containers/Layout'
import story from '../../config/text/story.json'

class Story extends React.Component {
  render () {
    return (
      <Layout heroSrc='heros/coffee-roaster-mark-lee.jpg' heroText={story.title} title={story.meta.title} description={story.meta.description}>
        <div className='story container'>
          <div className='row'>
            {story.description.map(paragraph => {
              return <div className='col-xs-12'><p>{paragraph}</p></div>
            })}
          </div>
        </div>
      </Layout>
    )
  }
}

export default Story
